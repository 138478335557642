import { SaveEditGuard } from './save-edit.guard';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { ErrorStateMatcher, MatNativeDateModule, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { FilterPipe } from './filter.pipe';
import { SafeHtml } from './safe-html.pipe.';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { NeedAuthGuard } from './_shared/NeedAuthGuard ';
import { Globals } from './globals';
import { Tools } from './_shared/tools';

import { AuthService } from './_services/auth.service';
import { ProductService } from './_services/product.service';
import { CustomerService } from './_services/customer.service';
import { CartService } from './_services/cart.service';
import { RegionalService } from './_services/regional.service';

import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoaderComponent } from './loader/loader.component';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { CatalogComponent } from './catalog/catalog.component';
import { CartComponent } from './cart/cart.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { OrderCompletedComponent } from './order-completed/order-completed.component';
import { ProductViewComponent } from './product-view/product-view.component';
import { CatalogListViewComponent } from './catalog-list-view/catalog-list-view.component';
import { CatalogGridViewComponent } from './catalog-grid-view/catalog-grid-view.component';
import { SalesPopUpComponent } from './sales-pop-up/sales-pop-up.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faFacebook, faYoutube, faTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { SanitizedHtmlPipe } from './sanitized-html.pipe';
import { ImageUrlPipe } from './image-url.pipe';
import { ServiceWorkerModule } from '@angular/service-worker';
// import { PaypalAngularModule, PaypalAppConfigModel } from 'paypal-angular';/
import { PaypalButtonComponent } from './paypal-button/paypal-button.component';
import { PaypalDirective } from './paypal.directive';
import { NgxPayPalModule } from 'ngx-paypal';
import { MatDialogModule } from '@angular/material/dialog';
import { RecaptchaModule } from 'ng-recaptcha';
import { OrderCancelledComponent } from './order-cancelled/order-cancelled.component';

// const paypalConfig: PaypalAppConfigModel = {
//   sandbox: 'AbGJ0mA1xJcHDXp_iUl91MNZtN6Ml79WwL-GrDeeddbnSQh7ae5AYVCd56FOHHAI_S1Azt7tkSy7mh9k',
//   production: 'AbGJ0mA1xJcHDXp_iUl91MNZtN6Ml79WwL-GrDeeddbnSQh7ae5AYVCd56FOHHAI_S1Azt7tkSy7mh9k',
// };

@NgModule({
  declarations: [
    AppComponent,
    FilterPipe,
    SafeHtml,
    HomeComponent,
    HeaderComponent,
    MainMenuComponent,
    LoaderComponent,
    CatalogComponent,
    CartComponent,
    CheckoutComponent,
    FooterComponent,
    OrderSummaryComponent,
    OrderCompletedComponent,
    ProductViewComponent,
    CatalogListViewComponent,
    CatalogGridViewComponent,
    SalesPopUpComponent,
    SanitizedHtmlPipe,
    ImageUrlPipe,
    PaypalButtonComponent,
    PaypalDirective,
    OrderCancelledComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatSelectModule,
    MatExpansionModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatCardModule,
    MatSnackBarModule,
    MatChipsModule,
    MatDividerModule,
    MatBadgeModule,
    MatRadioModule,
    DragDropModule,
    FontAwesomeModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: true }),
    NgxPayPalModule,
    MatDialogModule,
    RecaptchaModule,
    // PaypalAngularModule.forRoot(paypalConfig),
  ],
  providers: [
    MatNativeDateModule,
    NeedAuthGuard,
    Globals,
    Tools,
    AuthService,
    ProductService,
    CustomerService,
    CartService,
    RegionalService,
    SaveEditGuard,
    { provide: ErrorStateMatcher, useClass: ShowOnDirtyErrorStateMatcher },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faFacebook, faYoutube, faTwitter, faInstagram);
  }
}
