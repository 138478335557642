<div class="p-5 text-center">
  <h3>Thank you for your order!  </h3>
  <h3>Your order number is <strong>{{this.OrderId}}</strong></h3>
  <h4 style="color:red">
    REMINDER: Kindly check your registered email to confirm your order to proceed our transaction.
  </h4>
</div>

<div class="col-md-3 px-5" *ngIf="Payment==='ebank'">
  <a href="{{ ebankUrl }}/?oid={{this.OrderId}}&enc={{ this.EncOID }}" target="_blank" class="mat-focus-indicator w-100 p-0 mat-raised-button mat-button-base">Go to E-Bank</a>
</div>